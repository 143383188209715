// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/views/Front.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/views/Front.tsx");
  import.meta.hot.lastModified = "1702278741598.642";
}
// REMIX HMR END

import { useNavigate, useNavigation } from "@remix-run/react";
import { useCallback, useEffect, useRef } from "react";
import { Theme, useTheme } from "~/provider/theme-provider";
import { StrawberrySVG } from "~/resources/icons/StrawberrySVG";
export function Front() {
  _s();
  const svgRef = useRef(null);
  const [theme, setTheme] = useTheme();
  const navigate = useNavigate();
  const {
    state
  } = useNavigation();
  const changeTheme = useCallback(() => setTheme(theme === Theme.DARK ? Theme.LIGHT : Theme.DARK), [setTheme, theme]);
  useEffect(() => {
    if (svgRef.current) {
      const themeBtn = svgRef.current.getElementsByClassName('core')[0];
      const linkBtn = svgRef.current.getElementsByClassName('berry')[0];
      themeBtn.addEventListener('click', changeTheme);
      linkBtn.addEventListener('click', () => navigate('/welcome'));
      return () => {
        themeBtn.removeEventListener('click', changeTheme);
        linkBtn.removeEventListener('click', () => navigate('/welcome'));
      };
    }
  }, [navigate, changeTheme]);
  return <main className={`strawberry ${state}`} ref={svgRef}>
            <StrawberrySVG />
        </main>;
}
_s(Front, "fEOSCaF/hg6KypN/dYY3me7UQW4=", false, function () {
  return [useTheme, useNavigate, useNavigation];
});
_c = Front;
var _c;
$RefreshReg$(_c, "Front");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;